import { useSelector } from "react-redux";
import Author from "./Author";

const Everybody = () => {
    // console.log(issue);

    const authors = useSelector((state) => state.authors);

    return (
        <div className="everybody">
            {authors.map((author, i) => (
                <Author key={i} author={author} width={96} height={96} />
            ))}
        </div>
    );
};

export default Everybody;
