import React from "react";
import parse from "html-react-parser";
import { useFetch } from "../common/io";
import Spinner from "../Spinner";

const fileType = (src) => /\.(\w+)$/.exec(src)[1];

const File = ({ src, cacheParam={ cache: "no-store" }, children }) => {
    const [data, status, error] = useFetch({
        url: src,
        mimeType: "text/html",
        cacheParam,
    });

    // console.log(status);

    if (error) {
        console.log(error);
        return null;
    }

    if (status !== "fetched") {
        return <Spinner />;
    }

    /* if (id) {
        return <Test id={id} />
    } */

    // console.log(`fetched`, src);

    // console.log(data);

    const response = data.match(/<meta\s+\n*\r*data-response=['"]*(\w+\d+)['"]*\s*[>\/]/);

    if (response) {
        if (response[1] === "404") {
            console.log(src);
            console.log(`404: wrong file returned`);
            return children||null;
        }
    }

    let tag = fileType(src);
    /* switch (fileType(src)) {
            case "html"
        } */
    // console.log(src);

    const rx = new RegExp(`<${tag}(.|\\s)*<\\/${tag}>`);

    // console.log(rx);

    const content = data.match(rx);

    // console.log(`content:`, content);

    if (content === null) {
        console.log(`file type mismatch: ${src} contains no ${tag} tag`);
        return children||null;
    }

    let str = content[0]
        .replace(/(\r\n|\n|\r)/gm, " ")
        .replace(/>\s+</g, "><");

    // console.log(str);

    if (/\s\s/.test(str)) {
        for (let i = 0; i < 1000; i++) {
            if (!/\s\s/.test(str)) break;
            str = str.replace(/\s\s/g, " ");
        }
    }
    // console.log(str);

    str = str.replace(/>\s/g, ">").replace(/\s</g, "<");

    // console.log(str);

    if (tag === "html") {
        const parser = new DOMParser();
        str = parser.parseFromString(str, "text/html").body.innerHTML;
    }

    // const parser = new DOMParser();
    // const svg = parser.parseFromString(data.match(/<svg(.|\s)*<\/svg>/), "image/svg+xml");
    // console.log(`parsing str for ${src}`);
    const parsed = parse(str);
    // console.log(parsed);
    // console.log(svg);
    // console.log(id);
    return parsed;
};

export default React.memo(File, (prev, next) => prev.src === next.src);
