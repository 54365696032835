import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts, selectAllPosts, selectPostIds } from "./postsSlice";

const Feed = () => {

    const dispatch = useDispatch();
    const postsStatus = useSelector(state => state.posts.status);
    const postIds = useSelector(selectPostIds);
    const posts = useSelector(selectAllPosts);

    useEffect(() => {
        console.log("posts status:", postsStatus);
        if (postsStatus === "idle") {
            dispatch(fetchPosts());
        }
    }, [postsStatus, dispatch]);

    if (postsStatus === "succeeded") {
        console.log(posts);
    }

    return (
        <div>feed</div>
    )
};

export default Feed;
