import { Link } from "react-router-dom";
import File from "./common/file";

const Anonymous = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            clipRule="evenodd"
            viewBox="0 0 256 256"
            className="upic"
        >
            <g fill="none" stroke="#000" strokeWidth="7.6">
                <path d="M189.678 212.592s7.105-39.78 3.672-66.013c-3.432-26.233 3.705-43.225-4.198-67.547-7.903-24.322-36.64-35.484-59.752-37.069-23.113-1.585-44.224 8.601-54.417 22.617-10.194 14.016-19.453 23.236-16.711 54.66 2.742 31.424 21.611 45.364 29.75 54.701 8.138 9.338 36.863 16.577 53.404 14.086 16.541-2.49 33.574-20.648 36.943-25.742M84.426 213.594l2.765-36.122M147.508 89.145s-.087 8.843.361 13.31"></path>
                <path d="M100.021 90.407c1.036.843 4.092.859 3.707 5.623-.385 4.765 2.461 8.063-1.134 8.317-3.595.255-7.116-1.445-5.54-4.583 1.576-3.139 2.421-2.894 1.556-5.629M144.863 90.28s4.952.905 5.952 5.429c1 4.523-5.61 7.453-5.61 7.453l-1.098-5.997"></path>
            </g>
        </svg>
    );
};

const UPic = ({ children, width, height }) => {

    if (!width || !height) return children;

    const size = Math.min(width, height);
    const style = {
        width: width + "px",
        height: height + "px",
        position: "relative",
    };

    return (
        <div style={style} className="upic">
            <svg
                className="substrate"
                viewBox={`0 0 ${width} ${height}`}
                style={{ position: "absolute" }}
            >
                <circle r={size / 2} cx={size / 2} cy={size / 2} />
                {/* <circle r="50%" cx="50%" cy="50%" /> */}
            </svg>
            {children}
        </div>
    );
};

const Author = ({ author, width, height, nolink }) => {
    // console.log(width, height);
    const Wrap = nolink
        ? ({ children }) => <>{children}</>
        : ({ children }) => (
              <Link to={`/authors/${author.nick}/`}>{children}</Link>
          );

    return (
        <div className="author">
            <Wrap>
                <UPic {...{ width, height }}>
                    <File src={`/assets/i/upic-${author.nick}.svg`}>
                        <Anonymous />
                    </File>
                </UPic>
                {author.ru.name}
            </Wrap>
        </div>
    );
};

export default Author;
