import { useState } from "react";
import { useSelector } from "react-redux";
import { obj2unicode } from "./common/parse";
import { chance, getRandomValue, getType } from "./common/prototype";

const Cookie = ({ chance: ch }) => {
    const { cookieBride, cookieGroom, cookieIntro } = useSelector(
        (state) => state.randoms
    );
    
    if (!chance(ch)) return null;

    const groom = obj2unicode(getRandomValue(cookieGroom));
    const bride = obj2unicode(getRandomValue(cookieBride));
    const intro = obj2unicode(getRandomValue(cookieIntro));

    // console.log(chance(ch));

    return <Quest {...{ bride, groom, intro }} />;
};

const Quest = ({ bride, groom, intro }) => {
    const NOT_ANSWERED = "not answered",
        YES = "yes",
        NO = "no",
        PRECISE = "precise";

    const [state, setState] = useState({ q1: NOT_ANSWERED, q2: NOT_ANSWERED });

    // console.log(groom);
    // console.log(bride);

    return (state.q1 === NOT_ANSWERED || (state.q2 === NOT_ANSWERED || state.q2 === PRECISE) ?
        <div className="cookie">
            {(() => {
                if (state.q1 === NOT_ANSWERED) {
                    return (
                        <>
                            <div className="text">
                                <div className="intro">
                                    {intro}
                                </div>
                                Согласны ли вы, {groom.nom}, вступить
                                в&nbsp;брак с&nbsp;{bride.ins}?
                            </div>
                            <div className="buttons">
                                <button
                                    className="yes"
                                    onClick={() => {
                                        setState((prev) => ({ ...prev, q1: YES }));
                                    }}
                                >
                                    Согласен
                                </button>
                                <button
                                    className="no"
                                    onClick={() => {
                                        setState((prev) => ({ ...prev, q1: NO }));
                                    }}
                                >
                                    Не согласен
                                </button>
                            </div>
                        </>
                    );
                }

                if (state.q2 === NOT_ANSWERED) {
                    return (
                        <>
                            <div className="text">
                                А вы, {bride.nom}, согласны вступить в&nbsp;брак
                                с&nbsp;{groom.ins}?
                            </div>
                            <div className="buttons">
                                <button
                                    className="yes"
                                    onClick={() => {
                                        setState((prev) => ({ ...prev, q2: YES }));
                                    }}
                                >
                                    Согласна
                                </button>
                                <button
                                    className="no"
                                    onClick={() => {
                                        setState((prev) => ({ ...prev, q2: NO }));
                                    }}
                                >
                                    Не согласна
                                </button>
                                {groom.err && (
                                    <button
                                        className="precise"
                                        onClick={() => {
                                            setState((prev) => ({
                                                ...prev,
                                                q2: PRECISE,
                                            }));
                                        }}
                                    >
                                        {groom.precise}!
                                    </button>
                                )}
                            </div>
                        </>
                    );
                }

                if (state.q2 === PRECISE) {
                    return (
                        <>
                            <div className="text">
                                Так с&nbsp;{groom.precise} да или с&nbsp;{groom.precise} нет?
                            </div>
                            <div className="buttons">
                                <button
                                    className="yes"
                                    onClick={() => {
                                        setState((prev) => ({
                                            ...prev,
                                            q2: YES,
                                        }));
                                    }}
                                >
                                    с&nbsp;{groom.precise} согласна
                                </button>
                                <button
                                    className="no"
                                    onClick={() => {
                                        setState((prev) => ({
                                            ...prev,
                                            q2: NO,
                                        }));
                                    }}
                                >
                                    с&nbsp;{groom.precise} не&nbsp;согласна
                                </button>
                            </div>
                        </>
                    );
                }
            })()}
        </div>
    : null);
};

export default Cookie;
