import { DATA_LOADED, DATA_REQUESTED, API_ERRORED } from "./action-types";
import { call, put, takeEvery } from "redux-saga/effects";
import { mime } from "../common/io";

export default function* watcherSaga() {
    yield takeEvery(DATA_REQUESTED, workerSaga);
}

function* workerSaga(action) {
    // console.log(action.payload);
    try {
        const payload = yield call(fetchData, action.payload);
        yield put({ type: DATA_LOADED, payload });
    } catch (e) {
        yield put({ type: API_ERRORED, payload: { status: { [action.payload.key]: e } } });
    }
}

function fetchData({ path="", url, mimeType: mt, key: k, fetchParam={} }) {
    // console.log(`fetch ${path}${url}`);
    const key = k || url.match(/^([^\.]+)/)[1];
    const mimeType = mt || (()=>{
        const ext = url.split(".").pop();
        switch (ext) {
            case "json": {
                return "application/json"
            }
            case "html": {
                return "text/html"
            }
            case "txt": {
                return "text/plain"
            }
            default: return "text/plain"
        }
    })();
    // console.log(`mimeType: ${mimeType}`);
    // console.log(`key: ${key}`);
    // console.log(fetchParam);
    return fetch(`${path}${url}`, fetchParam).then(response => (
        mime(response, mimeType)
    )).then(value => ({ [key]: value, status: { [key]: "fetched" } }));
}
