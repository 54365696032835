import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers";
// import { mw } from "./middleware";
import createSagaMiddleware from "redux-saga";
import apiSaga from "./sagas";

const initialiseSagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(initialiseSagaMiddleware)
    )
);

initialiseSagaMiddleware.run(apiSaga);

export default store;
