import { Route, useParams, useRouteMatch } from "react-router-dom";

const Section = ({ character }) => {

    const { section } = useParams();

    return (
        <div>{character} / {section}</div>
    )

};

const Character = () => {

    const { nick } = useParams();

    const { url } = useRouteMatch();

    // console.log(routeMatch);

    return (
        <>
            <Route exact path={url}>
                <div>{nick}</div>
            </Route>
            <Route path={`${url}/:section`}>
                <Section character={nick} />
            </Route>
        </>
    )
};

export default Character;
