import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useFetch } from "../common/io";
import Spinner from "../Spinner";

const fileType = (src) => /\.(\w+)$/.exec(src)[1];

const File = ({
    src,
    id,
    setLoaded,
    cacheParam = { cache: "no-store" },
    children,
}) => {
    const [data, status, error] = useFetch({
        url: src,
        mimeType: "text/html",
        cacheParam,
    });

    const [parsed, setParsed] = useState(null);

    useEffect(() => {
        if (status === "fetched") {
            console.log(`FETCHED`);
            // if (setLoaded) setLoaded(true);

            const response = data.match(
                /<meta\s+\n*\r*data-response=['"]*(\w+\d+)['"]*\s*[>\/]/
            );

            if (response) {
                if (response[1] === "404") {
                    console.log(`404: wrong file returned`);
                    setParsed(children || null);
                    return;
                }
            }

            let tag = fileType(src);

            const rx = new RegExp(`<${tag}(.|\\s)*<\\/${tag}>`);
            // console.log(rx);

            const content = data.match(rx);
            // console.log(`content:`, content);

            if (content === null) {
                console.log(
                    `file type mismatch: ${src} contains no ${tag} tag`
                );
                setParsed(children || null);
                return;
            }

            let str = content[0]
                .replace(/(\r\n|\n|\r)/gm, " ")
                .replace(/>\s+</g, "><");
            // console.log(str);

            if (/\s\s/.test(str)) {
                for (let i = 0; i < 1000; i++) {
                    if (!/\s\s/.test(str)) break;
                    str = str.replace(/\s\s/g, " ");
                }
            }
            // console.log(str);

            str = str.replace(/>\s/g, ">").replace(/\s</g, "<");
            // console.log(str);

            if (tag === "html") {
                const parser = new DOMParser();
                str = parser.parseFromString(str, "text/html").body.innerHTML;
            }
            setParsed(parse(str));

        }
    }, [status, children, data, src]);

    useEffect(() => {
        if (setLoaded) {
            if (parsed) setLoaded(true);
        }
    }, [parsed, setLoaded]);

    // console.log(status);

    if (error) {
        console.log(error);
        return null;
    }
    
    if (status !== "fetched") {
        return <Spinner />;
    }

    return id ? <div ref={id}>{parsed}</div> : parsed;
};

export default File;
