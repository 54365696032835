import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { _s } from "./common/parse";
import { QHTML } from "./common/qhtml";
import AuthorName from "./Author";

const Author = ({ author: authorNick, page: { issue } }) => {
    const routeMatch = useRouteMatch();

    const nick = authorNick || routeMatch.params.author;

    const {
        fetchParam: { html: cacheParam },
        root,
        path: { html: path },
    } = useSelector((state) => state.params);

    const { works, author } = useSelector((state) => {
        const author = state.authors.find((a) => a.nick === nick);
        return {
            ...state,
            author,
            works: state.works.filter(
                (w) =>
                    w.author.nick === nick && (issue ? w.issue === issue : true)
            ),
        };
    });

    return (
        <div className="content author">
            <AuthorName author={author} nolink />
            <div className="bio">
                <QHTML src={`${path}${author.bio}`} cacheParam={cacheParam} />
            </div>
            <ul className="works">
                {works.map((work, i) => (
                    <li key={i} className="work">
                        <Link
                            to={`${root}/${issue || work.issue}/works/${
                                work.nick
                            }/`}
                        >
                            {_s(work.title)}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Author;
