import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import modules from "./modules";
import Spinner from "./Spinner";

// import "./css/fonts.scss";
import "./css/var.css";
import "./css/colors.css";
import "./css/begemot.scss";
import "./css/modules.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "./store/actions";
import Socialochka from "./socialochka/Index";

const Module = ({ module }) => {
    // console.log(module);

    if (!modules[module.nick]) return null;

    const Component = modules[module.nick];

    return <Component {...module} />;
};

const Page = React.memo(
    ({ page }) => {
        // console.log(`Page render`, page);

        return (
            <div className={`page ${page.nick}`}>
                {page.modules.map((module, i) => (
                    <Module key={i} module={{ ...module, page }} />
                ))}
            </div>
        );
    },
    (prev, next) => prev.page.nick === next.page.nick
);

function Pages({ root }) {
    const pages = useSelector((state) => {
        return state.issues
            .map((issue) =>
                issue.pages.map((page) => ({ ...page, issue: issue.nick }))
            )
            .flatten()
            .concat(state.pages);
    });

    return pages.map((page, i) => (
        <Route key={i} exact path={`${root}${page.href}`}>
            <Page page={page} />
        </Route>
    ));
}

const files = ["authors", "works", "issues", "pages", "chats", "randoms"];

function Home() {
    const dispatch = useDispatch();
    const params = useSelector((state) => {
        // console.log(`state:`, state);
        return state.params;
    });

    useEffect(() => {
        if (params) {
            const { fetchParam, path } = params;
            for (const file of files) {
                dispatch(
                    getData({
                        path: path.json,
                        fetchParam: fetchParam.json,
                        url: `${file}.json`,
                    })
                );
            }
        }
    }, [dispatch, params]);

    // if (!params) return <Spinner />;

    const allFetched = useSelector((state) =>
        files.every((file) => state.status[file] === "fetched")
    );

    if (allFetched) {
        // console.log("all fetched");
    } else {
        // console.log("not fetched yet");
        return <Spinner />;
    }

    return (
        <Pages root={params.root} />
    );
}

function Begemot() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getData({
                path: "/assets/json/",
                url: "config.json",
                key: "params",
                fetchParam: { cache: "no-store" },
            })
        );
    }, [dispatch]);

    const status = useSelector((state) => state.status.params);

    /*  store.subscribe(() => {
        console.log(store.getState());
    }); */

    // console.log("App render");

    if (status !== "fetched") {
        // console.log("params not loaded");
        return null;
    } else {
        // console.log("looks like params loaded");
    }

    return <Home />;
}

function App() {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/socialochka">
                    <Socialochka root="/socialochka" />
                </Route>
                <Begemot />
            </Switch>
        </BrowserRouter>
    )
}


export default App;
