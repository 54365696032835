import React, { useEffect, useState } from "react";
import { useFetch } from "./common/io";
import { _s } from "./common/parse";
import { getRandomValue } from "./common/prototype";
import Spinner from "./Spinner";
import { Button } from "./ui";


export const Loop = () => {

    const [index, setIndex] = useState();
    const [loop, setLoop] = useState();

    const [loops, status, error] = useFetch({ url: "/assets/comments.json", cacheParam: { cache: "no-store" } });

    useEffect(()=>{
        if (status==="fetched") {
            setIndex(0);
            setLoop(getRandomValue(loops));
        }
    }, [loops, status]);

    if (error) console.log(error);

    if (index === undefined) return <Spinner />

    // console.log(index);
    // console.log(loop[index].message.text);

    return (
        <div className="comments">
            <span>{_s(loop[index].message.text)}</span>
            {
                loop[index].button && <Button onClick={()=>{
                    // console.log("click");
                    setIndex(prev=> prev < loop.length-1 ? prev+1 : 0);
                }
            }>{loop[index].button.label}</Button>}
        </div>
    )

}
