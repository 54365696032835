import { useRef, useReducer, useEffect } from "react";

export const useFetch = ({
    url, mimeType="application/json", cacheParam: cp, callback
}) => {

    // console.log(`%cuseFetch: ${url}`, "color:orange");

	const cache = useRef({}), { current: cacheParam } = useRef(cp||{cache: "default"});

	const initialState = {
		status: 'idle',
		error: null,
		data: [],
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'FETCHING':
				return { ...initialState, status: 'fetching' };
			case 'FETCHED':
				return {
                    ...initialState, status: 'fetched',
                    data: callback ? callback(action.payload) : action.payload
                };
			case 'FETCH_ERROR':
				return { ...initialState, status: 'error', error: action.payload };
			default:
				return state;
		}
	}, initialState);

	useEffect(() => {
		let cancelRequest = false;
		if (!url) return;

		const fetchData = async () => {
			dispatch({ type: 'FETCHING' });
			if (cache.current[url]) {
				const data = cache.current[url];
				dispatch({ type: 'FETCHED', payload: data });
			} else {
				try {
                    const response = await fetch(url, cacheParam);

                    // console.log("RESPONSE:", response);
                    
                    let data;

                    switch (mimeType) {
                        case "application/json": {
                            data = await response.json();
                            break;
                        }
                        default: {
                            data = await response.text();
                        }
                    }
                    
					cache.current[url] = data;
					if (cancelRequest) return;
					dispatch({ type: 'FETCHED', payload: data });
				} catch (error) {
					if (cancelRequest) return;
					dispatch({ type: 'FETCH_ERROR', payload: error.message });
				}
			}
		};

		fetchData();

		return function cleanup() {
			cancelRequest = true;
		};
    }, [url, mimeType, cacheParam]);
    
    // console.log(state.status);

	return [state.data, state.status, state.error];
};

export const mime = (response, mimeType) => {
    switch (mimeType) {
        case "application/json": {
            return response.json();
        }
        default: {
            return response.text();
        }
    }
}

export const useFetches = ({
    fileNames, callbacks: cb,
    path="", mimeType="application/json", cacheParam: cp
}) => {

    const { current: files } = useRef(Array.isArray(fileNames) ? fileNames : [fileNames]);
    const { current: callbacks } = useRef(cb);
    const { current: cacheParam } = useRef(cp||{cache: "default"});

    // console.log(args);
    // console.log(`%cuseFetch: ${args.current.files}`, "color:orange");

	const initialState = {
		status: 'idle',
		error: null,
        data: []
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'FETCHING':
				return { ...initialState, status: 'fetching' };
			case 'FETCHED':
				return { ...initialState, status: 'fetched', data: action.payload };
			case 'FETCH_ERROR':
				return { ...initialState, status: 'error', error: action.payload };
			default:
				return state;
		}
	}, initialState);

	useEffect(() => {
		let cancelRequest = false;
        if (!files) return;

        // console.log(files);
        
        const fetchData = async () => {
			dispatch({ type: 'FETCHING' });
            try {
                const fetches = [];
                for (let i=0; i<files.length; i++) {
                    // console.log(path + fileName);
                    fetches.push(
                        fetch(path + files[i], cacheParam)
                        .then(response => mime(response, mimeType))
                    );
                }
                const data = await Promise.all(fetches);

                // console.log(data);
                if (cancelRequest) return;

                if (callbacks) {
                    for (let i=0; i<files.length; i++) {
                        if (callbacks[i]) {
                            data[i] = callbacks[i](data[i]);
                        }
                    }
                }
                
                dispatch({ type: 'FETCHED', payload: data });
            } catch (error) {
                if (cancelRequest) return;
                dispatch({ type: 'FETCH_ERROR', payload: error.message });
            }
		};

		fetchData();

		return function cleanup() {
			cancelRequest = true;
		};
	}, [files, path, mimeType, cacheParam, callbacks]);

	return [state.data, state.status, state.error];
};


export const simpleFetch = (fileName) => {

    // const src = "html/test.html";

    const src = `${process.env.PUBLIC_URL}/${fileName}`;


    async function fetchData() {
        const svg = await fetch(src)
            .then(res => res.text())
            .then(res => {
                console.log(res);
                const parser = new DOMParser();
                // return parser.parseFromString(res, "text/html");
                return parser.parseFromString(res, "image/svg+xml");
            });
        console.log(svg);
    }
    fetchData();

}

export function fetchFiles({ path, fileNames, mimeType, cacheParam, dispatch, setStatus }) {

    // mimeTypes = "image/svg+xml", "application/json"

    const localFileNames = Array.isArray(fileNames) ? fileNames : [fileNames];
    Promise.all(
        (() => {
            let files = [];
            for (const fileName of localFileNames) {
                files.push(fetch( `${process.env.PUBLIC_URL}/${path}${fileName}`, cacheParam||{ cache: "no-store" }))
            }
            return files;
        })()
    ).then(files =>
        Promise.all(
            (() => {
                let contents = [];
                for (const file of files) {
                    // console.log(file);
                    let content;
                    switch (mimeType) {
                        case "application/json": {
                            content = file.json();
                            break;
                        }
                        default: {
                            content = file.text();
                        }
                    }
                    // contents.push(file.text())
                    contents.push(content);
                }
                return contents;
            })()
        )
    ).then(source => {
        let parser = (raw) => raw;

        switch (mimeType) {
            case "image/svg+xml": {
                parser = (raw) => {
                    const parser = new DOMParser();
                    return raw.map(part => {
                        const res = parser.parseFromString(part, "image/svg+xml");
                        // console.log([...res.childNodes].find(node => node.tagName === "svg"));
                        return [...res.childNodes].find(node => node.tagName === "svg");
                    })
                }
                break;
            }
            default: {}
        }

        dispatch(parser(source));
        setStatus({ loaded: true });
    }).catch(error => {
        setStatus({ error });
    })
}

export function fetchFiles0({ path, fileNames, cacheParam, dispatch, setStatus }) {
    const fns = Array.isArray(fileNames) ? fileNames : [fileNames];

    Promise.all(
        (() => {
            const files = [];
            for (const fileName of fns) {
                console.log(`fetching ${process.env.PUBLIC_URL}/${path}${fileName}`)
                files.push({
                    fileName,
                    content: fetch( `${process.env.PUBLIC_URL}/${path}${fileName}`, cacheParam||{ cache: "no-store" })
                })
            }
            return files;
        })()
    ).then(files =>
        Promise.all(
            (() => {
                const contents = [];
                for (const file of files) {
                    console.log(file);
                    contents.push(
                        /\.json$/.test(file.fileName) ? file.content.json() : file.content.text()
                    )
                }
                return contents;
            })()
        )
    ).then(source => {
        console.log("fetchFiles: resolve");
        //console.log([].concat(source).flatten());
        dispatch(source);
        setStatus({ loaded: true });
        // setStatus(prev => ({ ...prev, loaded: true }));
    }).catch(error => {
        console.log("fetchFiles: error");
        setStatus({ error });
        // setStatus((prev) => ({...prev, error: err }));
    })
}
