// https://stackoverflow.com/questions/17722497/scroll-smoothly-to-specific-element-on-page

import { getType } from "./prototype";

export function scrollParentTo(element, duration) {
    // var startingY = window.pageYOffset;
    const parent = element.parentNode;
    const startingY = element.scrollTop;
    // var diff = elementY - startingY;
    const diff = element.offsetTop - parent.offsetTop - startingY;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed milliseconds since start of scrolling.
        let time = timestamp - start;
        // Get percent of completion in range [0, 1].
        let percent = Math.min(time / duration, 1);

        parent.scrollTo(0, startingY + diff * percent);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
            window.requestAnimationFrame(step);
        }
    });
}

let f;

export function scrollTo(element, to, duration) {

    // https://stackoverflow.com/questions/27980084/scrolling-to-a-element-inside-a-scrollable-div-with-pure-javascript

    let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 1000/60;

    const animateScroll = function () {
        currentTime += increment;
        const val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
            cancelAnimationFrame(f);
            f = requestAnimationFrame(animateScroll);
            // setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};

export function cl() {
    const classList = Array.prototype.slice.call(arguments)
    .filter(c=>c)
    .map(c => {
        if (getType(c) === "object") {
            return Object.keys(c).map(key => (
                c[key] === true
                ? key
                : false
            )).filter(c=>c);
        }
        return c
    })
    .flatten();

    // console.log(classList);

    return classList.length > 0 ? classList.filter(c=>c).join(" ") : null;
};
