
import parse from "html-react-parser";
import { getType } from "./prototype";

export function entities2unicode(str) {
    if (getType(str) !== "string") return str;
    let res = str;
    [
        { e: "#9312", u: "\u2460" },
        { e: "#9313", u: "\u2461" },
        { e: "#9314", u: "\u2462" },
        { e: "#9315", u: "\u2463" },
        { e: "#9316", u: "\u2464" },
        { e: "#9317", u: "\u2465" },
        { e: "#9318", u: "\u2466" },
        { e: "#9319", u: "\u2467" },
        { e: "#9320", u: "\u2468" },

        { e: "times", u: "\u00D7" },
        { e: "thinsp", u: "\u2009" },
        { e: "copy", u: "\u00A9" },
        { e: "nbsp", u: "\u00A0" },
        { e: "mdash", u: "\u2014" },
        { e: "ndash", u: "\u2013" },
        { e: "shy", u: "\u00AD" },
        { e: "bdquo", u: "\u201E" }, { e: "ldquo", u: "\u201C" },
        { e: "laquo", u: "\u00AB" }, { e: "raquo", u: "\u00BB" }
    ].forEach(entity => { res = res.replace(new RegExp(`&${entity.e};`, "g"), entity.u) });
    return res;
}

export const obj2unicode = (obj) => {

    if (Array.isArray(obj)) {
        return obj.map(element => obj2unicode(element));
    }

    if (getType(obj) === "string") return entities2unicode(obj);

    if (getType(obj) !== "object") return obj;
    
    const res = {};
    const keys = Object.keys(obj);
    for (let i=0; i<keys.length; i++) {
        const key = keys[i];
        const val = obj[key];
        switch(getType(val)) {
            case "string": {
                res[key] = entities2unicode(val);
                break;
            }
            default: {
                res[key] = obj2unicode(val);
            }
        }
    }
    return res;
};

export const cap = str => `${str[0].toUpperCase()}${str.substr(1)}`;

const syn = {
    "a": "āá",
    "e": "ēé",
    "i": "īí",
    "o": "ó",
    "u": "ūú",
    "ch": "č",
    "g": "ģ"
}

export function sameCase(origin, str) {
    if (origin.toUpperCase() === origin && origin.toLowerCase() !== origin) return str.toUpperCase();
    if (origin.toLowerCase() === origin && origin.toUpperCase() !== origin) return str.toLowerCase();
    return str;
}

export const removeDiacritics = (str) => {
    // console.log(str);
    let res = str.split(""), keys = Object.keys(syn);
    for (let i = 0; i < keys.length; i++) {
        const rx = new RegExp(`[${syn[keys[i]]}]`, "i");
        if (rx.test(str)) {
            const diacritics = syn[keys[i]];
            for (let j = 0; j < diacritics.length; j++) {
                const index = str.toLowerCase().indexOf(diacritics[j]);
                if (index < 0) continue;
                res[index] = sameCase(str[index], keys[i]);
            }
        }
    }
    return res.join("");
}



export const _s = (str) => {

    let res = entities2unicode(str);

    if (/<\w+/.test(str)) {
        res = parse(res);
    }

    return res;

};