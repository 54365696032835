
import React from "react";
import parse from "html-react-parser";
import { camelCase } from "./prototype";
import { useFetch } from "./io";
import Spinner from "../Spinner";

const Error = () => {
    return (
        <div>error</div>
    )
};

const QTest = (props) => {
    console.log(props);
    return (
        <div>qtest</div>
    )
};

const qc = [
    {
        nodeName: "qtest",
        component: QTest
    }
];

export const QHTML = ({ src, cacheParam }) => {

    // console.log(`QHTML render ${src}`);

    const [source, status, error] = useFetch({ url: src, mimeType: "text/html", cacheParam });

    if (error) {
        console.log(error);
        return <Error {...{ error }} />
    }

    // console.log(status);

    if (status !== "fetched") return <Spinner />

    // console.log(src);

    const path = src.replace(/[^\/]+.html$/, "");

    // console.log(path);

    let html = source
        .replace(/%path%/g, path)
        .replace(/>\s+</g, "><")
        .replace(/\s{2,}/g, " ");

    // console.log(html);

    qc.forEach(node => {
        const tag = node.nodeName;
        const rx = new RegExp(`<${tag}\\s[^>]+\\/>|<${tag}\\s*\\/>`);
        // console.log(rx);
        if (rx.test(html)) {
            // console.log("test true");
            const rx = new RegExp(`(<${tag}\\s[^>]+)\\/>`, "g");
            // console.log(rx.exec(source));
            html = html.replace(rx, `$1></${tag}>`);
        } else {
            // console.log("test false");
        }
    });

    const parser = new DOMParser();

    html = parser.parseFromString(html, "text/html").body.innerHTML;
    // console.log(html);

    const parsed = parse(html, {
        trim: true, replace: node => {
            // console.log(node);
            if (node.type === "tag") if (node.name[0] === "q") {
                const qnode = qc.find(q => q.nodeName === node.name);
                // console.log(node);
                // console.log(qnode);
                if (qnode) {
                    let attrs = {};
                    const Component = qnode.component;
                    Object.keys(node.attribs).forEach(key => { attrs[camelCase(key)] = node.attribs[key] });
                    // console.log(attrs);
                    return <Component {...attrs} />
                }
            }
        }
    });

    return (
        parsed
    )

};