import React from "react";
import { QHTML } from "./common/qhtml";
import { Loop } from "./comments";
import Chat from "./moduleChat";
import { useRouteMatch, Link } from "react-router-dom";
import { entities2unicode, _s } from "./common/parse";
import { useSelector } from "react-redux";
import Everybody from "./moduleEverybody";
import { Cassete } from "./Spinner";
import AuthorLink from "./Author";
import { cl } from "./common/dom";
import Author from "./moduleAuthor";
import Cookie from "./moduleCookie";

const Head = ({ inner }) => {
    // console.log(inner);

    const root = useSelector((state) => state.params.root);

    // console.log(root);

    return (
        <div className={cl("head", { inner })}>
            {inner ? (
                <Link to={root}>
                    <img src="/assets/i/begemot.svg" alt="Бегемот" />
                </Link>
            ) : (
                <img src="/assets/i/begemot.svg" alt="Бегемот" />
            )}
        </div>
    );
};

const IssueTOC = ({ page: { issue } }) => {
    // console.log(issue);

    const authors = useSelector((state) => {
        const nicks = state.works
            .filter((w) => w.issue === issue)
            .map((w) => w.author.nick)
            .unique();

        // console.log(nicks);

        return nicks.map((nick, i) => {
            const theAuthor = state.authors.find((a) => a.nick === nick);
            if (theAuthor)
                return (
                    <div key={i} className="author">
                        <Link
                            to={`${state.params.root}/${issue}/authors/${nick}/`}
                        >
                            {theAuthor[state.params.lang].name}
                        </Link>
                    </div>
                );
            return null;
        });
    });

    return (
        <div className="authors">
            <h2>В номере:</h2>
            {authors}
        </div>
    );
};

const Editorial = () => {
    return <div className="editorial">
        <img src="/assets/begemot-1998.png" width="779" height="893" />
    </div>;
};

const FullText = ({ work: workNick }) => {
    // console.log(workNick);

    // const { struc, settings } = useContext(Context);

    const routeMatch = useRouteMatch();

    // console.log(routeMatch);

    // console.log(struc);

    const nick = workNick || routeMatch.params.work;

    const {
        fetchParam: { html: cacheParam },
        root,
        lang,
        path: { html: path },
    } = useSelector((state) => state.params);

    const { work, author } = useSelector((state) => {
        const work = nick && state.works.find((w) => w.nick === nick);
        return {
            work,
            author: state.authors.find((a) => a.nick === work.author.nick),
        };
    });

    // const work = nick && struc.works.find(w => w.nick === nick);
    // console.log(work);
    if (!work) return null;
    // const author = struc.authors.find(a => a.nick === work.author.nick);

    // console.log(author);

    const t = <QHTML src={`${path}${work.src}`} cacheParam={cacheParam} />;

    return (
        <div className={cl("content", work.form)}>
            <AuthorLink {...{ author }} width={40} height={40} />
            <h1>{_s(work.title)}</h1>
            {t}
        </div>
    );
};

const Label = ({ text }) => {
    return <div className="label">{entities2unicode(text)}</div>;
};

const modules = {
    FullText,
    Head,
    Loop,
    Editorial,
    IssueTOC,
    Author,
    // Chat,
    Everybody,
    Label,
    Cassete,
    Cookie
};

export default modules;
