import { ADD_WORK, API_ERRORED, DATA_LOADED } from "./action-types";

const initialState = {
    works: [],
    authors: [],
    issues: [],
    pages: [],
    status: {}
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case ADD_WORK: {
            return {
                ...state,
                works: [...state.works, action.payload]
            }
        }
        case DATA_LOADED: {
            // console.log(action.payload);
            const { status, ...data } = action.payload;
            return {
                ...state,
                ...data,
                status: { ...state.status, ...status }
            }
        }
        case API_ERRORED: {
            console.log(`Error:`, action.payload.status);
            return {
                ...state,
                status: { ...state.status, ...action.payload.status }
            }
        }
        default: {
            return state;
        }
    }
}

export default reducer;
