import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { scrollTo } from "./common/dom";
import { useFetch } from "./common/io";
import smoothscroll from "smoothscroll-polyfill";
import Author from "./Author";
import { Cassete, PlayPause } from "./Spinner";
// import { Spinner } from "./ui";

smoothscroll.polyfill();

const mapText = ({ text, authors, writingSpeed, minPause }) => {
    const regexp = `(?:^|\\n)\\s*([a-zа-я]+:|_[\\d\\.]+)`;

    const rx = new RegExp(regexp, "i");

    const isName = (str) => /^[a-zа-я]+:$/.test(str);

    let totalTime = 0;

    // console.log(rx);

    const arr = text
        .split(rx)
        .map((str) => str.trim())
        .filter((str) => str !== "");
    // console.log(arr);
    /* arr.forEach((str) => {
        console.log(str);
    }); */

    const res = [];
    let position = 0;
    let nick, author;
    for (let i = 0; i < arr.length; i++) {
        if (isName(arr[i])) {
            nick = arr[i].replace(/:$/, "");
            const rx = new RegExp(`^${nick}$`, "i");
            author = authors.find((a) => rx.test(a.ru.nick) || rx.test(a.nick));
        } else if (/^_[\d\.]+$/.test(arr[i])) {
            // console.log("pause");
            // console.log(arr[i]);
            // console.log(arr[i].match(/_([\d\.]+)/));
            const pause = parseFloat(arr[i].match(/_([\d\.]+)/)[1]);
            const timeout = 1000 / pause;
            res.push({
                position,
                pause,
                timeout,
            });
            position++;
            totalTime += timeout;
        } else {
            const text = arr[i].split(/\n/).map((str) => str.trim());
            const timeout = speedTimeout(
                text.join(" "),
                writingSpeed, minPause
            );
            res.push({
                position,
                author,
                text, timeout
            });
            position++;
            totalTime += timeout;
        }
    }

    // console.log(res);

    return [res, totalTime];
};

const reducer = (state, action) => {
    switch (action.type) {
        case "update": {
            return {
                ...state,
                ...action.payload,
            };
        }
        case "reset": {
            return action.payload;
        }
        default:
            return state;
    }
};

const actionPlayPause = (state) => {
    return {
        type: "update",
        payload: { play: !state.play, changed: Date.now() },
    };
};

const actionRewind = () => ({
    type: "update",
    payload: { rewind: Date.now() }
})

/* const actionPause = (state) => {
    return {
        type: "update",
        payload: { play: false },
    };
}; */

const Msg = ({ msg, id }) => {
    return (
        <div ref={id} className="msg">
            <Author author={msg.author} width={24} height={24} />
            <div className="text">
                {msg.text.map((line, j) => (
                    <div className="line" key={j}>
                        {line}
                    </div>
                ))}
            </div>
        </div>
    );
};

const FuckingReact = ({ chat }) => {

    const refId = useRef();

    useEffect(()=>{
        if (refId.current) {
            // console.log(`last msg rendered:`, id.current);
            refId.current.scrollIntoView({behavior: "smooth", block: "nearest"});
            const element = refId.current;
            const parent = element.parentNode;
            // parent.scrollTop = element.offsetTop - parent.offsetTop;
            scrollTo(parent, element.offsetTop - parent.offsetTop, 1000 / 60 * 20);
            // scrollParentTo(id.current, 500);
        }
    });

    return (
        <div className="chat">
            {chat.length > 0 &&
                chat.map((msg, i) => {
                    // console.log(chat);
                    const id = i===chat.length-1 ? refId : null;
                    if (msg.author) {
                        return (
                            <Msg key={i} {...{ msg, id }} />
                        )
                    }
                    if (msg.placeholder) {
                        return (
                            <div ref={id} className="placeholder" key={i}>{msg.placeholder}</div>
                        )
                    }
                    if (msg.title) {
                        return (
                            <div ref={id} className="title" key={i}>{msg.title}</div>
                        )
                    }
                    return null;
                })
            }
        </div>
    );
};

const speedTimeout = (string, speed, min) => {
    const timeout = string.length / speed;
    return (timeout < min ? min : timeout) * 1000;
};

const replaceLast = (arr, next) => {
    const updated = [...arr];
    updated.pop();
    updated.push(next);
    return updated;
}

const timeoutEndingTitle = 2000, timeoutStartingTitle = 2000;

let timeout, passed = 0, aborted = false, started, rewind = false;

const Chat = React.memo(
    ({ state, dispatch, record: { chat: origin, time } }) => {
        // console.log(`Chat`);

        const [chat, setChat] = useState([]);

        const timeoutId = useRef(null);
        const position = useRef(0);
        const startOver = useRef(true);

        const maxPosition = origin.length - 1;

        // const { chat: { writingSpeed, minPause } } = useSelector(state => state.params);

        const { current: startingTitle } = useRef({
            title: `Запись от ${time.toJSON()}`
        });

        const { current: endingTitle } = useRef({
            title: `на этом запись обрывается`
        });

        const loop = useCallback(
            (play, changed) => {
                function t() {
                    // console.log(`t: position:`, position.current);

                    if (!play) {
                        // console.log(`stopped, exiting`);
                        aborted = true;
                        passed = changed - started;
                        return;
                    }

                    // console.log(next);

                    let payload, placeholder, slide = 1;

                    if (position.current > maxPosition) {
                        // payload = (prev) => [...prev, ]
                        payload = prev => [...prev, endingTitle];
                        timeout = timeoutEndingTitle;
                        position.current = 0;
                        slide = 0;
                        startOver.current = true;
                    } else {

                        if (startOver.current) {
                            payload = [startingTitle];
                            timeout = timeoutStartingTitle;
                            startOver.current = false;
                            slide = 0;
                            rewind = true;
                        } else {
                            const next = origin[position.current];
                            timeout = next.timeout - passed;
                            payload = (prev) => [...prev, next];
                            /* if (!next.hasOwnProperty("pause")) {
                                if (!aborted) {
                                    placeholder = prev => [
                                        ...prev,
                                        { placeholder: <span>next: {timeout.toFixed(0)}ms</span> }
                                    ];
                                } else {
                                    placeholder = prev => [
                                        ...prev,
                                        { placeholder: <span>resume: {next.timeout.toFixed(0)}-{passed} = {timeout.toFixed(0)}ms</span> }
                                    ];
                                }
                                payload = (prev) => replaceLast(prev, next);
                            } */
                        }
                    }
                    // console.log(`loop: clear timeout`);
                    aborted = false;
                    clearTimeout(timeoutId.current);
                    if (placeholder) setChat(placeholder);
                    started = Date.now();
                    passed = 0;
                    timeoutId.current = setTimeout(() => {
                        if (payload) setChat(payload);
                        position.current += slide;
                        if (rewind) {
                            // console.log(`REWIND`);
                            dispatch(actionRewind());
                            rewind = false;
                        }
                        t();
                    }, timeout);
                }
                t();
            },
            [origin, maxPosition, startingTitle, endingTitle, dispatch]
        );

        useEffect(() => {
            // console.log(`play: ${state.play}`);
            loop(state.play, state.changed);
            return () => {
                // console.log(`useEffect clear timeout`);
                clearTimeout(timeoutId.current);
            };
        }, [state.play, state.changed, loop]);

        return <FuckingReact {...{ chat }} />;
    },
    (prev, next) => {
        /* console.log(`prev chat === next chat?`, prev.chat === next.chat);
    console.log(`prev play === next play?`, prev.state.play === next.state.play);

    console.log(`prev.chat`, prev.chat);
    console.log(`prev.state.play`, prev.state.play); */
        return prev.chat === next.chat && prev.state.play === next.state.play;
    }
);

const Controls = ({ state, dispatch, duration, record }) => {
    // console.log(`Controls render`);

    // console.log(record);

    return (
        <div className="tools">
            <span>Расшифровка черного ящика № {record.number}:&nbsp;</span>
            <PlayPause
                onClick={() => {
                    dispatch(actionPlayPause(state));
                }} play={state.play} />
            <Cassete play={state.play} rewind={state.rewind} duration={duration} />
        </div>
    );
};

const Player = ({ chat }) => {
    // console.log("Player render");

    // console.log(chat);

    const { src, time, number } = chat;

    const initialState = useRef({});

    const [state, dispatch] = useReducer(reducer, initialState.current);

    const {
        fetchParam: { text: cacheParam },
        path: { text: path },
    } = useSelector((state) => state.params);

    const authors = useSelector((state) => state.authors);

    const { chat: { writingSpeed, minPause } } = useSelector(state => state.params);

    const [text, status, error] = useFetch({
        url: `${path}${src}`,
        mimeType: "text/plain",
        cacheParam,
    });

    const record = useMemo(() => {
        if (status !== "fetched") return null;
        // console.log(`creating chat...`);
        const [chat, totalTime] = mapText({ text, authors, writingSpeed, minPause });
        // console.log(res);
        return { chat, time: new Date(time), totalTime, number };
    }, [status, text, authors, time, writingSpeed, minPause, number]);

    /* useEffect(() => {
        return () => { dispatch(actionPause()); }
    }, []); */

    if (error) console.log(error);

    return (
        <div className="player">
            {record && (
                <>
                    <Controls {...{
                        state, dispatch, record,
                        duration: timeoutStartingTitle + record.totalTime + timeoutEndingTitle
                    }} />
                    <Chat {...{ state, dispatch, record }} />
                </>
            )}
        </div>
    );
};

const WrappedChat = () => {

    // console.log(props);

    const { params: routeParams } = useRouteMatch();

    // console.log(routeParams);

    const chats = useSelector(state => state.chats);
    
    // console.log(chats);

    const chat = chats.find(chat => {
        const keys = Object.keys(chat.params);
        return keys.every(key => (
            Array.isArray(chat.params[key])
            ? chat.params[key].some(param => routeParams[key] === param)
            : chat.params[key] === routeParams[key]
        ));
    });

    if (!chat) return null;

    // console.log("chat found:", chat);

    return <Player {...{ chat }} />

};

export default WrappedChat;
