import React from "react";

export const Button = ({ className, children, ...props }) => {

    const classList = [""];

    if (className) classList.push(className);

    return (
        <button className={classList.lenth>0?classList.join(" "):null} {...props} >{children}</button>
    )
}
