import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import Character from "./Character";
import Feed from "./feed/Feed";
import rimi from "./rimi";

// const APP_ID = process.env.REACT_APP_ID;
// console.log(process.env);

const Home = ({ root }) => {

    return (
        <>
            <Route exact path={root}>
                <Feed />
            </Route>
            <Route path={`${root}/:nick`}>
                <Character />
            </Route>
        </> 
    );
};

const Index = ({ root }) => {

    return (
        <Provider store={rimi}>
            <Home root={root} />
        </Provider>
    );
};

export default Index;
