import { useEffect, useRef, useState } from "react";
import File from "./common/file_hooked";
import { KeyshapeJS } from "./common/keyshape";
import { Triangle } from "./common/svg";

const Spinner = () => {
    return <div>...</div>;
};

const Test = ({ id, children }) => {
    return <div ref={id}>{children}</div>;
};

/* (function (ks) {
                ks.animate(
                    "#left_band",
                    [
                        {
                            p: "scaleX",
                            t: [0, t],
                            v: [2.6, 1],
                            e: [[0], [0]],
                        },
                        {
                            p: "scaleY",
                            t: [0, t],
                            v: [2.6, 1],
                            e: [[0], [0]],
                        },
                    ],
                    "#right_band",
                    [
                        {
                            p: "scaleX",
                            t: [0, t],
                            v: [1, 2.6],
                            e: [[0], [0]],
                        },
                        {
                            p: "scaleY",
                            t: [0, t],
                            v: [1, 2.6],
                            e: [[0], [0]],
                        },
                    ],
                    "#right_wheel",
                    [
                        {
                            p: "rotate",
                            t: [0, 500],
                            v: [-15, -75],
                            e: [[0], [0]],
                            iterations: Infinity,
                        },
                    ],
                    "#left_wheel",
                    [
                        {
                            p: "rotate",
                            t: [0, 500],
                            v: [15, -45],
                            e: [[0], [0]],
                            iterations: Infinity,
                        },
                    ],
                    { autoremove: false }
                ).range(0, t);
                if (
                    document.location.search
                        .substr(1)
                        .split("&")
                        .indexOf("global=paused") >= 0
                )
                    ks.globalPause();
            })(ks.current); */

function CasseteInline({ id }) {
    return (
        <svg
            ref={id}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 58 20"
            style={{ whiteSpace: "preserve-spaces" }}
        >
            <defs>
                <mask id="Mask-2">
                    <path
                        fill="#ddd"
                        d="M0 28c15.464 0 28-12.536 28-28S15.464-28 0-28-28-15.464-28 0s12.536 28 28 28zM0 8A8 8 0 100-8 8 8 0 000 8z"
                        transform="translate(10 10)"
                    ></path>
                </mask>
                <mask id="Mask-1">
                    <path
                        fill="#ddd"
                        d="M0 28c15.464 0 28-12.536 28-28S15.464-28 0-28-28-15.464-28 0s12.536 28 28 28zM0 8A8 8 0 100-8 8 8 0 000 8z"
                        transform="translate(10 10)"
                    ></path>
                </mask>
                <clipPath id="mainClip">
                    <rect
                        width="58"
                        height="20"
                        fill="#ddd"
                        rx="10"
                        transform="translate(27.5,9.5) translate(-27.5,-9.5)"
                    ></rect>
                </clipPath>
            </defs>

            <g clipPath="url(#mainClip)">
                <g transform="translate(15,10) translate(-15,-10)">
                    <g
                        mask="url(#Mask-2)"
                        transform="translate(10,10) translate(-10,-10)"
                    >
                        <ellipse
                            id="left_band"
                            rx="10"
                            ry="10"
                            fill="rgb(128,125,122)"
                            clipRule="evenodd"
                            fillRule="evenodd"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            transform="translate(10,10) scale(2.6,2.6)"
                        />
                    </g>
                    <g
                        mask="url(#Mask-1)"
                        transform="translate(48,10) translate(-10,-10)"
                    >
                        <ellipse
                            id="right_band"
                            rx="10"
                            ry="10"
                            fill="rgb(128,125,122)"
                            clipRule="evenodd"
                            fillRule="evenodd"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            transform="translate(10,10)"
                        />
                    </g>
                </g>
                <g transform="translate(29,10) translate(-29,-10)">
                    <path
                        id="shadow"
                        d="M64,15C64,9.481,59.519,5,54,5L16,5C10.481,5,6,9.481,6,15C6,20.519,10.481,25,16,25L54,25C59.519,25,64,20.519,64,15ZM51.666,6.211C47.777,7.243,44.908,10.789,44.908,15C44.908,19.211,47.777,22.757,51.666,23.789L18.334,23.789C22.223,22.757,25.092,19.211,25.092,15C25.092,10.789,22.223,7.243,18.334,6.211L51.666,6.211ZM16,8C19.863,8,23,11.137,23,15C23,18.863,19.863,22,16,22C12.137,22,9,18.863,9,15C9,11.137,12.137,8,16,8ZM54,8C57.863,8,61,11.137,61,15C61,18.863,57.863,22,54,22C50.137,22,47,18.863,47,15C47,11.137,50.137,8,54,8Z"
                        fillOpacity="0.2"
                        transform="translate(29,10) translate(-35,-15)"
                    />
                    <path
                        id="right_wheel"
                        d="M54,7C58.415,7,62,10.585,62,15C62,19.415,58.415,23,54,23C49.585,23,46,19.415,46,15C46,10.585,49.585,7,54,7ZM54.868,10.832L54.868,9.14C56.39,9.363,57.725,10.167,58.64,11.319L57.176,12.164L58.044,13.668L59.509,12.822C59.776,13.496,59.923,14.231,59.923,15C59.923,15.769,59.776,16.504,59.509,17.178L58.044,16.332L57.176,17.836L58.64,18.681C57.725,19.833,56.39,20.636,54.868,20.86L54.868,19.168L53.132,19.168L53.132,20.86C51.61,20.637,50.275,19.833,49.36,18.681L50.824,17.836L49.956,16.332L48.491,17.178C48.224,16.504,48.077,15.769,48.077,15C48.077,14.231,48.224,13.496,48.491,12.822L49.956,13.668L50.824,12.164L49.36,11.319C50.275,10.167,51.61,9.364,53.132,9.14L53.132,10.832L54.868,10.832Z"
                        fill="rgb(180,180,180)"
                        transform="translate(48,10) rotate(-15) translate(-54,-15)"
                    />
                    <path
                        id="left_wheel"
                        d="M16,7C20.415,7,24,10.585,24,15C24,19.415,20.415,23,16,23C11.585,23,8,19.415,8,15C8,10.585,11.585,7,16,7ZM16.868,10.832L16.868,9.14C18.39,9.363,19.725,10.167,20.64,11.319L19.176,12.164L20.044,13.668L21.509,12.822C21.776,13.496,21.923,14.231,21.923,15C21.923,15.769,21.776,16.504,21.509,17.178L20.044,16.332L19.176,17.836L20.64,18.681C19.725,19.833,18.39,20.636,16.868,20.86L16.868,19.168L15.132,19.168L15.132,20.86C13.61,20.637,12.275,19.833,11.36,18.681L12.824,17.836L11.956,16.332L10.491,17.178C10.224,16.504,10.077,15.769,10.077,15C10.077,14.231,10.224,13.496,10.491,12.822L11.956,13.668L12.824,12.164L11.36,11.319C12.275,10.167,13.61,9.364,15.132,9.14L15.132,10.832L16.868,10.832Z"
                        fill="rgb(180,180,180)"
                        transform="translate(10,10) rotate(15) translate(-16,-15)"
                    />
                </g>
            </g>
        </svg>
    );
}

export const Cassete = ({ duration = 12000, play, rewind }) => {
    // console.log("Cassete render");

    const svgDocument = useRef();

    const ks = useRef();

    const timeline = useRef();

    const animation = useRef([
        "#left_band",
        [
            { p: "scaleX", t: [0, duration], v: [2.6, 1], e: [[0], [0]] },
            { p: "scaleY", t: [0, duration], v: [2.6, 1], e: [[0], [0]] },
        ],
        "#right_band",
        [
            {
                p: "scaleX",
                t: [0, duration],
                v: [1, 2.6],
                e: [[0], [0]],
            },
            {
                p: "scaleY",
                t: [0, duration],
                v: [1, 2.6],
                e: [[0], [0]],
            },
        ],
        "#right_wheel",
        [
            {
                p: "rotate",
                t: [0, 500],
                v: [-15, -75],
                e: [[0], [0]],
                iterations: Infinity,
            },
        ],
        "#left_wheel",
        [
            {
                p: "rotate",
                t: [0, 500],
                v: [15, -45],
                e: [[0], [0]],
                iterations: Infinity,
            },
        ],
        { autoremove: false, autoplay: false }
    ]);

    useEffect(() => {
        if (svgDocument.current) {
            // console.log("loaded");
            const svgElement = svgDocument.current;
            // console.log(svgElement);
            // return;
            ks.current = KeyshapeJS(svgElement);
            // console.log(animation.current);
            timeline.current = ks.current.animate(animation.current).range(0, duration);
        } else {
            // console.log("not loaded");
        }
    }, [duration]);

    useEffect(() => {
        if (rewind) timeline.current.time(0);
    }, [rewind]);

    useEffect(() => {
        if (play) {
            // ks.current.globalPlay();
            timeline.current.play();
        } else {
            timeline.current.pause();
            // ks.current.globalPause();
        }
    }, [play]);

    return (
        <div className="cassete">
            <CasseteInline id={svgDocument} />
        </div>
    );
};

export const PlayPause = ({ play, onClick }) => {

    const scale = 0.54, cx = 15, cy = 15, r = 15;
    const transform = `translate(${(1 - scale) * cx}, ${(1 - scale) * cy}) scale(${scale})`;

    return (
        <button className="playPause" onClick={onClick}>
            <svg viewBox="0 0 30 30">
                <circle {...{cx, cy, r}} className="substrate" />
                <g transform={transform}>
                    {
                        play
                        ? <g>
                            <rect x="3" y="3" width="10" height="24" />
                            <rect x="17" y="3" width="10" height="24" />
                        </g>
                        : <Triangle point={{ x: 15, y: 15 }} r={15} direction="right" />
                    }
                </g>
            </svg>
        </button>
    )
};

export const Cassete0 = ({ t = 14000 }) => {
    // console.log("Cassete render");

    const svgDocument = useRef();

    const [loaded, setLoaded] = useState();

    useEffect(() => {
        if (loaded) {
            // console.log("loaded");

            const svgElement = svgDocument.current.childNodes[0];
            // console.log(svgElement);
            // return;
            const instance = KeyshapeJS(svgElement);
            // return;
            // const ks = instance;

            (function (ks) {
                ks.animate(
                    "#left_band",
                    [
                        {
                            p: "scaleX",
                            t: [0, t],
                            v: [2.6, 1],
                            e: [[0], [0]],
                        },
                        {
                            p: "scaleY",
                            t: [0, t],
                            v: [2.6, 1],
                            e: [[0], [0]],
                        },
                    ],
                    "#right_band",
                    [
                        {
                            p: "scaleX",
                            t: [0, t],
                            v: [1, 2.6],
                            e: [[0], [0]],
                        },
                        {
                            p: "scaleY",
                            t: [0, t],
                            v: [1, 2.6],
                            e: [[0], [0]],
                        },
                    ],
                    "#right_wheel",
                    [
                        {
                            p: "rotate",
                            t: [0, 500],
                            v: [-15, -75],
                            e: [[0], [0]],
                            iterations: Infinity,
                        },
                    ],
                    "#left_wheel",
                    [
                        {
                            p: "rotate",
                            t: [0, 500],
                            v: [15, -45],
                            e: [[0], [0]],
                            iterations: Infinity,
                        },
                    ],
                    { autoremove: false }
                ).range(0, t);
                if (
                    document.location.search
                        .substr(1)
                        .split("&")
                        .indexOf("global=paused") >= 0
                )
                    ks.globalPause();
            })(instance);
        } else {
            // console.log("not loaded");
        }
    });

    return (
        <File
            setLoaded={setLoaded}
            id={svgDocument}
            src="/assets/i/player2.svg"
        />
    );
};

export default Spinner;
