import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

const api_posts = "https://begemot.fish/php/api/posts/";

const postsAdapter = createEntityAdapter();

const initialState = postsAdapter.getInitialState({
    status: "idle",
    error: null,
});


export const fetchPosts = createAsyncThunk(
    "posts/fetchPosts",
    async () => {
        let data;
        try {
            const response = await fetch(api_posts, {
                cache: "no-store",
            });
            data = await response.json();
            if (response.ok) {
                return data;
            }
            throw new Error(response.statusText);
        } catch (err) {
            return Promise.reject(err.message ? err.message : data);
        }
    }
);

const postsSlice = createSlice({
    name: "posts",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPosts.pending]: state => { state.status = "loading" },
        [fetchPosts.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [fetchPosts.fulfilled]: (state, action) => {
            state.status = "succeeded";
            postsAdapter.upsertMany(state, action.payload);
        }
    }
});

export const actions = postsSlice.actions;

export default postsSlice.reducer;

export const {
    selectAll: selectAllPosts,
    selectById: selectPostById,
    selectIds: selectPostIds,
} = postsAdapter.getSelectors(state => state.posts);

export const selectPostsByAuthorId = createSelector(
    [selectAllPosts, (state, authorId) => authorId],
    (posts, authorId) => posts.filter(post => post.authorId === authorId)
);
